<template>
  <div :class="`flex flex-col justify-between items-center p-7-5 ${!isMobileDevice ? 'feedback-container' : 'flex-1 sm:p-4'}`">
    <div class="w-full">
      <div class="flex justify-between">
        <div class="text-center text-xl33 font-bold mx-auto md:text-xl24">
          {{$t('feedback.give_us_feedback')}}
        </div>
      </div>

      <textarea ref="feedbackRef" :placeholder="$t('feedback.tell_us')" data-lpignore="true" v-model="feedback" class="v1" rows="5" />
    </div>

    <div class="flex flex-col w-full mt-n-4xl">
      <ButtonV2 @onClick="submitFeedback"
        :inactive="isLoading || !feedback"
        :label="$t('feedback.send')"
        testId="btn-send"
        wide
      />
    </div>
  </div>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2';
import SUBMIT_FEEDBACK from '@/graphql/mutations/SubmitFeedback.gql';

export default {
  name: 'SubmitFeedback',
  components: {
    ButtonV2,
  },

  data() {
    return {
      isLoading: false,
      feedback: '',
    };
  },

  mounted() {
    this.$refs.feedbackRef.focus();
  },

  methods: {
    async submitFeedback() {
      try {
        this.isLoading = true;

        await this.$apollo.mutate({
          mutation: SUBMIT_FEEDBACK,
          variables: {
            feedback: this.feedback,
          },
        });

        this.showSuccess(this.$t('success.feedback_sent'));

        this.hideModal();
      } catch (err) {
        await this.showError(err);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.feedback-container {
  width: 550px;
  overflow: auto;
}
</style>
